export default function beforeComa(price) {
    let strPrice = ""
    let dotIndex = price.indexOf(".")
    if(dotIndex > -1) {
        strPrice = price.slice(0, dotIndex)
        let convertComa = strPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return convertComa
    } else {
        strPrice = price.slice(0)
        let convertComa = strPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return convertComa
    }
}

